.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: none;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.left-nav h1 {
  font-size: 24px;
  color: #EDE6E3;
  margin: 0;
}

.right-nav {
  display: flex;
  align-items: center;
}

.right-nav div {
  margin-right: 20px;
  transition: color 0.3s ease;
}

.right-nav div:last-child {
  margin-right: 0;
}

.right-nav a {
  text-decoration: none;
  color: #EDE6E3;
  font-weight: 500;
  font-size: 16px;
}

.right-nav .active a {
  color: #0099CC;
}

.right-nav a:hover {
  color: #0099CC;
}
