.about-us-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-container h1 {
    color: #333;
  }
  
  .about-us-container p {
    color: #666;
    line-height: 1.6;
  }

  .Mission-Container{
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform:translateY(100px);
  }

  .TeamMembers{
    width: 1300px;
    height: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #e8eef9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateY(50px);
    z-index: 1;
  }
  .TeamMembers h1{
    color: #333;
  }
 

  .umer-container {
    max-width: 200px; /* Limiting the container size */
    max-height: 200px;
    margin:0 auto;
    display: flex; /* Using flex to center the image */
    justify-content: center;
    align-items: center;
    transform:translateY(-2px);
    z-index:2;
  }
  
  .umer-photo {
    max-width: 200px; /* Make the image responsive within the container */
    max-height: 200px;
    border-radius: 50%; /* If you want the image to be circular */
    z-index: 3;
    transform:translateY(-100px);
    transform:translateX(-250px);
  }
  .umer-container h1 {
    color: #333;
    font-size:15px;
    transform:translateX(-250px);
  }


  .Rogelio-container {
    max-width: 200px; /* Limiting the container size */
    max-height: 200px;
    margin:0 auto;
    display: flex; /* Using flex to center the image */
    justify-content: center;
    align-items: center;
    transform:translateY(-200px);
    z-index:2;
  }
  
  .Rogelio-photo {
    max-width: 200px; /* Make the image responsive within the container */
    max-height: 200px;
    border-radius: 50%; /* If you want the image to be circular */
    z-index: 3;
    
    transform:translateX(-125px);
    margin:0 auto;
  }
  .Rogelio-container h1 {
    color: #333;
    font-size: 15px;
    transform:translateX(-150px);
  }

  .Amina-container {
    max-width: 200px; /* Limiting the container size */
    max-height: 200px;
    margin:0 auto;
    display: flex; /* Using flex to center the image */
    justify-content: center;
    align-items: center;
    transform:translateY(-900px);
    transform:translateX(500px);
    z-index:2;
  }
  
  .Amina-photo {
    max-width: 300px; /* Make the image responsive within the container */
    max-height: 200px;
    border-radius: 50%; /* If you want the image to be circular */
    transform:translateY(-400px);
    z-index: 3;
    margin:0 auto;
  }
  .Amina-container h1 {
    color: #333;
    
    transform:translateY(-405px);
    font-size: 15px;
  }

  .Ramiro-container {
    max-width: 175px; /* Limiting the container size */
    max-height: 200px;
    margin:0 auto;
    display: flex; /* Using flex to center the image */
    justify-content: center;
    align-items: center;
    transform:translateY(-210px);
    z-index:2;
  }
  
  .Ramiro-photo {
    width: 160px; /* Make the image responsive within the container */
    max-height: 210px;
    border-radius: 50%; /* If you want the image to be circular */
    z-index: 3;
    transform:translateX(10px);
    transform:translateY(-95px);
    margin:0 auto;
  }
  .Ramiro-container h1 {
    color: #333;
    font-size:15px;
    transform:translateY(-100px);
  }

  .Matthew-container {
    max-width: 240px; /* Limiting the container size */
    max-height: 200px;
    display: flex; /* Using flex to center the image */
    justify-content: center;
    align-items: center;
    transform:translateX(250px);
    transform:translateY(-50);
    z-index: 2;
    margin:0 auto;
  }
  
  .Matthew-photo {
    max-width: 240px; /* Make the image responsive within the container */
    max-height: 200px;
    border-radius: 50%; /* If you want the image to be circular */
    z-index: 3;
    transform: translateY(-300px); /* Adjust position as needed */
    margin: 0 auto;
  }
  .Matthew-container h1 {
    color: #333;
    font-size:15px;
    transform:translateY(-300px);
  }
  
  
  
  


 
  