.home-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffffff; /* Adjust text color as needed */
}

.content-block {
  max-width: 600px;
  text-align: left;
}

h1 {
  font-size: 36px;
  color: #EDE6E3; /* Blue-green accent color */
  margin-bottom: 0px;
}

h2 {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #0099CC;
}

p {
  font-size: 18px;
  line-height: 1.6;
  color: #EDE6E3;
  margin-top: 0px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.btn {
  padding: 0px 20px;
  height: 60px;
  width: 180px;
  border: solid;
  border-radius: 5px;
  border-color: #EDE6E3;
  font-size: 16px;
  color:#EDE6E3;
  cursor: pointer;
  background-color: #081b29; /* Matching the header */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-photo {
  width: 400px;
  height: 400px;
  border-radius: 50%; /* Circular image */
  object-fit: cover;
  border: Solid;
  border-color: #EDE6E3;
  border-width: 10px;
}

.icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: solid;
  border-radius: 5px;
  border-color: #EDE6E3;
  padding: 7px;
}